.section-about{
    min-height: 22.6rem;
    font: Montserrat;
  }
  
.description-about{
  color: var(--bs-gray-dark);
  font-size: 22px;
}
  
.container-img-about{
  top: -1rem;
  left: 34rem;
}
  
.containter-title-about{
  width: 78%;
  color: var(--bs-gray);
}

.container-img-about>figure>img{
    width: 400px;
    height: 400px;
}

/************** media queries *********************/
@media (max-width: 992px) {
  .container-description-about{
    flex-wrap: wrap;
  }
  .container-img-about>figure>img{
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 576px){
  .containter-title-about{
    width: 100%;
  }
}

@media (min-width: 1400px){
  .containter-title-about{
    width: 67%;
  }
}

@media (min-width: 992.01px) and (max-width: 1199.99px) {
  .containter-title-about{
    width: 94%;
  }
}

@media (min-width: 768px) and (max-width: 991.99px) {
  .containter-title-about{
    width: 67%;
  }
}