.img-manuales{
    margin-left: 135px;
}
.img-motorizados{
    margin-right: 151px;
}
.cursor-pointer{
    cursor: pointer;
}
.sub-description-toldos{
    margin-bottom: 7rem;
    width: 60%;
}
.container-videos{
    z-index: 1;
    margin-top: -7rem;
}
.color-bg{
    background-color: var(--bs-dark-blue);
    padding-bottom: 9rem;
}
.color-bg>h2{
    color: var(--bs-white);
}
/************** media queries *********************/
@media (max-width: 576px) {
    .enable-figure{
        display: none;
    }
    .img-manuales{
        margin-left: 10px;
    }
    .img-motorizados{
        margin-right: 10px;
    }
    .txt-movil{
        font-size: 48px;
    }
    .sbtxt-movil{
        font-size: 3.1rem;
    }

    .sbtxt-movil2{
        font-size: 1.1rem;
    }
    
    .content-movil-toldos-videos{
        flex-wrap: wrap;
    }
    .content-movil-toldos-videos>div{
        width: 100% !important;
    }
    .figure-toldos{
        width: 25% !important;
    }
    .figure-toldos-altura{
        height: 32% !important;
    }
}