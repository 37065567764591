.etitulo {
    font-size: 75px;
    font-weight: bold;
    color: var(--bs-dark-blue);
}


.elevadoresp {
    font-size: 30px;
}

.alinearletraselevador {
    margin-bottom: 42px;
}


@media screen and (max-width: 767px) {


    .elevadoresp {
        font-size: 1rem;
        width: 100%;
    }

    .ziseTitle{
        font-size: 1.5em;
    }

    .etitulo {
        font-size: 1.5em;
        color: var(--bs-dark-blue);
        font-weight: 700 !important;
        text-transform: uppercase !important;
    }

    .ling-elevador {
        flex-direction: column;

    }

}

/* -------------------------- vista de computadora-----------------------*/
@media screen and (max-width:1023px) and (min-width:768px) {
    .mb-movil-5{
        margin-bottom: 3rem;
    }
    
    .ling-elevador {
        flex-direction: column;

    }

    .telefono-w-100 {

        width: 100% !important;
    }

    .spacepie {
        margin-bottom: 99px;
    }
}