.img-sombrilla-doble{
    width: 22rem;
    height: 22rem;
    display: flex;
    align-self: center;
}
.container-content{
    width: 569px;
}
.content-img-movil{
    width: 49%;
}
.title-estructuras{
    font-size: 80px;
    position: absolute;
}
.title-estructuras1{
    top:32px;
}
.title-estructuras2{
    top:110px;
}
.description-estructuras{
    font-size: 55px;
    position: absolute;
    top: 190px;
}
.estructuras2{
    width: 320px;
    z-index: -1;
}
.estructuras3{
    margin-top: -10px !important;
    margin-right: 100px !important;
    width: 315.69px;
}

.ziseTitle{
    font-size: 80px !important;
}
.font-size-titles{
    font-size: 3rem !important;
}
.font-size-descriptions{
    font-size: 2rem !important;
}

@media (max-width: 1200px) {
    .container-content{
        width: 100%;
    }
    .title-estructuras,.description-estructuras{
        font-size: 3rem;
        position: inherit;
        text-align: center;
    }
    .container-movil{
        flex-wrap: wrap;
    }
    .content-img-movil,.content-img-movil-hijo{
        flex-direction: unset !important;
        gap: 2rem;
        margin-top: 2rem;
        align-items: unset;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .estructuras2,.estructuras3{
        width: 100%;
        margin: 0 !important;
    }
    .content-img-movil-hijo{
        margin: 0;
    }
  }

@media (max-width: 768px) {
    .container-content{
        width: 100%;
    }
    .estructuras2,.estructuras3{
        width: 100%;
        margin: 0 !important;
    }
    .content-img-movil{
        width: 100%;
    }
}