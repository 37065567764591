.picture-overlay-data {
    background-color: transparent;
    background-image: linear-gradient(90deg, #09031D 20%, #2B1A6600 100%);
    opacity: 0.8;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    position: absolute;
}
.paddingCenter {
    padding: 4.22rem 0;
}
.separatorColorProduct {
    background-color: var(--bs-dark-blue);
    padding: 8rem 0;
    position: relative;
    width: 8%;
}
.containerDescription {
    color: var(--bs-white);
    margin-left: -60px;
}
.textFont {
    font-weight: bold;
    font-size: 2.63rem;
}
.textColorBlue {
    color: var(--bs-dark-blue);
}
.texttitulo {
    font-size: 50px;
    color: var(--bs-dark-blue);
    font-weight: bold;
}
.textodesc2 {
    line-height: 2rem;
}
.texto_2 {
    color: var(--bs-dark-blue);
    font-size: 65px;
    font-weight: bold;
}
.textopie {
    color: var(--bs-dark-blue);
    font-size: 60px;
    font-weight: bold;
}
.textopie2 {
    color: white;
    font-size: 60px;
    font-weight: bold;
}
.texto_tittfica {
    color: var(--bs-dark-blue);
    font-size: 3.5rem;
}
.texto_tittfica2 {
    color: var(--bs-dark-green);
    font-size: 3.5rem;
}
.bajarletras {
    margin-top: 94px;
}
.duela_pinturas {
    height: 30rem;
}
.transformed {
    /* idéntico a rotateZ(45deg); */
    transform: rotate(45deg);
    background-color: blue;
}
.pidecotiza {
    background: var(--bs-dark-blue);
}
.padre {
    position: relative;
}
.transfom {
    padding-bottom: 0;
    height: 1813px;
    background: linear-gradient(11deg, white 5%, var(--bs-light-green) 6%, var(--bs-light-green) 32%, var(--bs-dark-blue) 32%, var(--bs-dark-blue) 36%, white 36%);
    position: absolute;
    top: -120%;
    z-index: -1;
}
.trabajador {
    height: 900px;
    width: 900px;
}
.letraslineas {
    color: white;
    font-size: 35px;
}
.first {
    height: 4729px;
    width: 100%;
    background: linear-gradient(280deg, white 8%, #F3F3F3 9%, #F3F3F3 22%, white 23%);
    z-index: -3;
    position: absolute;
}
.second {
    height: 3627px;
    width: 100%;
    background: linear-gradient(72deg, white 43%, #F3F3F3 44%, #F3F3F3 55%, white 57%);
    z-index: -3;
    position: absolute;
}
.lineas_fondo {
    position: absolute;
}
.cortinasp {
    font-size: 30px;
}
.contworker {
    margin-bottom: -92px;
}
.muestrac {
    height: 600px;
    width: 600px;
}
.spintando {
    margin-bottom: -155;
}
.duelas {
    margin-top: -96px
}
.duelasp {
    font-size: 34px;
}
.seccionlineas {
    min-height: 800px;
}
.marginproductos {
    height: 96.1px;
}
.daralinicacio {
    margin-bottom: 5rem;
}
.soloariba {
    margin-top: 5rem;
}

.container-sizes {  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 10px;
    grid-auto-flow: row;
    text-align: center;
    grid-template-areas:
      "pequeño mediano grande";
    color: #fff;
  }
  
.pequeño, .mediano, .grande{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 10px 0px;
    grid-auto-flow: row;
}

.title-pequeño, .title-mediano, .title-grande, .description-g1,
.description-g2, .description-g3, .description-m1, .description-m2,
.description-m3, .description-p1, .description-p2, .description-p3{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.title-pequeño, .title-grande, .description-g1,
.description-g2, .description-g3, .description-p1, .description-p2, .description-p3{
    background-color: var(--bs-dark-green);
}

.title-mediano, .description-m1, .description-m2,
.description-m3{
    background-color: var(--bs-light-green);
}

  .pequeño {  
    grid-template-areas:
      "title-pequeño"
      "description-p1"
      "description-p2"
      "description-p3";
    grid-area: pequeño;
  }
  
  .title-pequeño { 
    grid-area: title-pequeño;
}
  
  .description-p1 { 
    grid-area: description-p1; 
}
  
  .description-p2 {
    grid-area: description-p2;
  }
  
  .description-p3 {
    grid-area: description-p3;
  }
  
  .mediano {
    grid-template-areas:
      "title-mediano"
      "description-m1"
      "description-m2"
      "description-m3";
    grid-area: mediano;
  }
  
  .title-mediano {
    grid-area: title-mediano;
  }
  
  .description-m1 {
    grid-area: description-m1;
  }
  
  .description-m2 {
    grid-area: description-m2;
  }
  
  .description-m3 { 
    grid-area: description-m3;
  }
  
  .grande {
    grid-template-areas:
      "title-grande"
      "description-g1"
      "description-g2"
      "description-g3";
    grid-area: grande;
  }
  
  .title-grande {
    grid-area: title-grande;
  }
  
  .description-g1 {
    grid-area: description-g1;
  }
  
  .description-g2 {
    grid-area: description-g2;
  }
  
  .description-g3 {
    grid-area: description-g3;
  }
  


@media screen and (min-width:1366px) {


    .daralinicacio {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .soloariba {
        margin-top: 5rem;
    }

    .spacepie {
        margin-bottom: 99px;
    }

    .transfom {
        padding-bottom: 0;
        height: 1763px;
        ;
        background: linear-gradient(11deg, white 5%, var(--bs-light-green) 6%, var(--bs-light-green) 32%, var(--bs-dark-blue) 32%, var(--bs-dark-blue) 36%, white 36%);
        position: absolute;
        top: -120%;
        z-index: -1;

    }

    .mwtr {
        margin-right: -34px;
    }

    .trabajador {
        height: 750px;
        width: 750px;
    }

    .bajarletras {
        margin-top: 259px;
    }

    .upcblue {
        margin-top: -49px;
    }


}

/* Estilos  767px  vista para telefono
*/
@media screen and (max-width: 767px) {
    .mb-movil-5{
        margin-bottom: 3rem;
    }

    .marginproductos {
        height: 89.1px;

    }



    .telefono {
        flex-wrap: wrap;
    }

    .bajartt {
        margin-bottom: 3rem;
    }

    .muestrac {
        height: 100%;
        width: 100%;

    }

    .telefono-w-100 {

        width: 100% !important;
    }

    .contworker {
        margin-bottom: -20px;
    }

    .trabajador {
        height: 100%;
        width: 100%;
    }


    .telefono-reverse {
        flex-wrap: wrap-reverse;

    }


    .estilost {

        padding-bottom: 3rem;
    }

    .tptelefono {
        font-size: 1.5em;
        color: var(--bs-dark-blue);
        font-weight: 700 !important;
        text-transform: uppercase !important;


    }




    .bajarletras {
        margin-top: 242px;
    }


    .bajarlineas {
        margin-bottom: 290px;
    }

    .letraslineas {
        font-size: 25px;
    }

    .cortinasp {
        font-size: 1rem;
        width: 100%;
    }

    .duelasp {
        font-size: 1rem;
        width: 100%;
    }

    .motoresp {
        font-size: 1rem;
        width: 100%;
    }



    .duelas {
        gap: 0 !important;
    }

    .duelas>figure {
        margin-bottom: 0;
    }

    .textopie2 {
        color: white;
        font-size: 40px;
        font-weight: bold;
    }

    .first {
        height: 4500px;
    }




}

/*------------ vista de tablet ---------*/

@media screen and (min-width:768px) and (max-width:1365px) {


    .duelas {
        margin-top: 0px !important;
    }

    /*.marginproductos {
        margin-top: 4rem;
    }*/

    .transfom {
        padding-bottom: 0;
        height: 2187px;
        background: linear-gradient(8deg, white 5%, var(--bs-light-green) 6%, var(--bs-light-green) 31%, var(--bs-dark-blue) 32%, var(--bs-dark-blue) 36%, white 37%);
        position: absolute;
        top: -120%;
        z-index: -1;

    }

    .tablet {
        flex-wrap: wrap;
    }

    .trabajador {
        height: 100%;
        width: 100%;
    }

    .bajarletras {
        margin-top: 278px;
    }



}