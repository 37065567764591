.content-table::-webkit-scrollbar{
    height: 0px;
  }
  
.content-table::-webkit-scrollbar-thumb{
  background: var(--bs-dark-green);
  border-radius: 3px;
}
.cotizar {
    background-color: var(--bs-light-green);
    border-radius: 60px;
    font-size: 50px;
    font-weight: bold;
    padding: 1rem 11rem;
}

.motoresh2 {
    color: var(--bs-dark-blue);
    font-size: 40px;
}

.motoresp {
    font-size: 25px;
}

.alinearmotorr {
    margin-bottom: 192px;
}

.alinearmotornice {
    margin-bottom: 138px;
}

.alinearroller {
    margin-bottom: 85px;
}

.alinearrib {
    margin-bottom: 159px;
}

@media screen and (max-width: 767px) {

    .cotizar {
        background-color: var(--bs-light-green);
        border-radius: 60px;
        font-size: 26px;
        font-weight: 700;
        padding: 1rem 6rem;
    }

    .letraspie {
        text-align: center;
    }

    .motoresp {

        font-size: 1rem;
        width: 100%;
    }

}



@media screen and (min-width:768px) and (max-width:1023px) {


    .aling-catalogo {
        display: flex !important;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 107px;

    }

    .cotizar {
        background-color: var(--bs-light-green);
        border-radius: 60px;
        font-size: 39px;
        font-weight: 700;
        padding: 1rem 6rem;
    }

    .letraspie {
        text-align: center;
    }



}