.carrusel-container {
    overflow: hidden;
    padding: 2rem 0;
    position: relative;
    width: 100%;
  }
  
  .carrusel-track {
    animation: scroll 25s linear infinite;
    display: flex;
    gap: 2rem;
    width: fit-content;
  }
  
  .carrusel-track:hover {
    animation-play-state: paused;
  }
  
  .carrusel-item {
    width: 400px;
    height: 400px;
    flex-shrink: 0;
    transition: transform 0.3s ease;
  }
  
  .carrusel-item:hover {
    transform: scale(1.05);
  }
  
  .carrusel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-400px * 5 - 2rem * 5));
    }
  }
  
  