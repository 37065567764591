.color-check{
    color: var(--bs-dark-blue);
}
.pm-mueble{
    padding-top: 10rem;
    margin-bottom: 4rem;
}

.title-sub-mueble{
    font-size: 3.8rem;
    line-height:0.8;
    width: 492px;
}

.backgroun-green-light{
    background-color: var(--bs-light-green);
}

.color-green-light{
    color: var(--bs-light-green);
}

.color-green-dark{
    color: var(--bs-dark-green);
}

.description-mueble{
    font-size: 1.5rem;
}

.gap-especial{
    gap: 4.2rem !important;
}

.color-title-description{
    color: var(--bs-dark-blue);
}

.bg-color-description{
    background-color: var(--bs-white);
    width: 65%;
}
.w-description-especial{
    width: 70%;
}

.colors-trill1{
    background-color: #727a54;
}
.colors-trill2{
    background-color: #E7a044;
}
.colors-trill3{
    background-color: #a59d9a;
}
.colors-trill4{
    background-color: #101010;
}
.colors-trill5,.colors-doga2,.colors-stool3,.colors-clip2,.colors-komodo2{
    border: 1px solid;
    background-color: var(--bs-white);
}

.colors-doga3{
    background-color: #575962;
}
.colors-doga1{
    background-color: #984c4f;
}

.colors-stool1{
    background-color: #6a5c53;
}
.colors-stool2{
    background-color: #313133;
}
.colors-stool4{
    background-color: #42737d;
}
.colors-stool5{
    background-color: #7d8462;
}
.colors-stool6{
    background-color: #9b8e86;
}
.colors-stool7{
    background-color: #c7c7c7;
}
.colors-stool8{
    background-color: #cca225;
}

.colors-clip1{
    background-color: #3b3b3c;
}
.colors-clip3{
    background-color: #a59d97;
}
.colors-clip4{
    background-color: #9ca381;
}

.colors-komodo1{
    background-color: #202020;
}
.colors-komodo3{
    background-color: #2e2e2e;
}
.colors-komodo4{
    background-color: #92877d;
}
.sub-komodo1{
    background-color: #fac9bb;
}
.sub-komodo2{
    background-color: #fac9bb;
}
.sub-komodo3{
    background-color: #a9a9af;
}
.sub-komodo4{
    background-color: #a9a9af;
}

.colors{
    border-radius: 50%;
    height: 30px;
    margin-bottom: 0.5rem;
    width: 30px;
}
.subColors{
    border-radius: 50%;
    height: 15px;
    width: 15px;
    z-index: 2;
}
.border-bottom-colores{
    border-bottom: 1px solid var(--bs-gray);
}
.content-description-scroll::-webkit-scrollbar{
    height: 0px;
    width: 0px;
}
@media (max-width: 1100px) {
    .content-movil-muebles-figures{
        width: 25% !important;
    }
    .gap-especial{
        gap: 1.5rem !important;
    }
    .wrap-especial{
        flex-wrap: wrap;
    }
}